var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "mb-1", attrs: { "no-body": "" } }, [
        _c("div", { staticClass: "m-2" }, [
          _c("div", { staticClass: "form-row justify-content-end" }, [
            _c("div", { staticClass: "col-md-2" }, [
              _c("div", { staticClass: "form-group mb-md-0 mb-1" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.name,
                      expression: "filters.name",
                    },
                  ],
                  staticClass: "form-control d-inline-block",
                  attrs: { type: "search", placeholder: "Código" },
                  domProps: { value: _vm.filters.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "name", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-7" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "v-select",
                    {
                      attrs: {
                        label: "title",
                        "item-text": "title",
                        "item-value": "code",
                        placeholder: "Curso",
                        options: _vm.optionsCourses,
                        clearable: true,
                      },
                      on: { search: _vm.fetchCourse },
                      model: {
                        value: _vm.filters.course,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "course", $$v)
                        },
                        expression: "filters.course",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "text-left",
                          attrs: { slot: "no-options" },
                          slot: "no-options",
                        },
                        [_vm._v(" Digite o título do curso ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      placeholder: "Situação",
                      options: [
                        { code: 1, title: "Ativo" },
                        { code: 0, title: "Inativo" },
                      ],
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "status", $$v)
                      },
                      expression: "filters.status",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      placeholder: "Está confirmado?",
                      options: _vm.optionsConfirmed,
                      reduce: function (option) {
                        return option.value
                      },
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.confirmed,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "confirmed", $$v)
                      },
                      expression: "filters.confirmed",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      placeholder: "Unidade",
                      options: _vm.optionsUnits,
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.unit,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "unit", $$v)
                      },
                      expression: "filters.unit",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      placeholder: "Turno",
                      options: _vm.optionsShifts,
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.shift,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "shift", $$v)
                      },
                      expression: "filters.shift",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0" },
                [
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Período de início",
                      config: {
                        mode: "range",
                        altInput: true,
                        dateFormat: "Y-m-d",
                        altFormat: "d/m/Y",
                        locale: "pt",
                      },
                    },
                    model: {
                      value: _vm.filters.rangeDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "rangeDate", $$v)
                      },
                      expression: "filters.rangeDate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-1" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-block",
                  staticStyle: { padding: "14px !important" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.getData()
                    },
                  },
                },
                [
                  !_vm.btnSearch
                    ? _c("i", { staticClass: "bi bi-search" })
                    : _c("b-spinner", {
                        attrs: { small: "", variant: "light" },
                      }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
              busy: _vm.btnSearch,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("justTime")(data.item.start_time)) +
                        " às " +
                        _vm._s(_vm._f("justTime")(data.item.end_time)) +
                        " - " +
                        _vm._s(data.item.course_title) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(unit_name)",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.unit_name) + " ")]
                },
              },
              {
                key: "cell(postponement)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("datePT")(data.item.postponement, false)
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(total)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("toCurrency")(data.item.total)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(confirmed_date)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          data.item.confirmed_date
                            ? "Sim, em " + _vm.datePT(data.item.confirmed_date)
                            : "Não"
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function (data) {
                  return [
                    _c(
                      "b-badge",
                      {
                        staticClass: "d-block",
                        attrs: {
                          variant:
                            data.item.status === 1
                              ? "light-success"
                              : "light-danger",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              data.item.status === 1 ? "Ativo" : "Inativo"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-primary my-2" },
                      [
                        _c("b-spinner", {
                          staticClass: "align-middle",
                          staticStyle: { "margin-right": "3px" },
                          attrs: { small: "" },
                        }),
                        _c("strong", [_vm._v(" carregando...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              rowsTable: _vm.rowsTable,
              currentPage: _vm.filters.currentPage,
              perPage: _vm.filters.perPage,
              totalRows: _vm.totalRows,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }