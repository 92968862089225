<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="m-2">
        <div class="form-row justify-content-end">
          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <input
                type="search"
                class="form-control d-inline-block"
                placeholder="Código"
                v-model="filters.name"
              />
            </div>
          </div>
          <div class="col-md-7">
            <div class="form-group">
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.course"
                placeholder="Curso"
                :options="optionsCourses"
                @search="fetchCourse"
                :clearable="true"
              >
                <span slot="no-options" class="text-left">
                  Digite o título do curso
                </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                required
                placeholder="Situação"
                v-model="filters.status"
                :options="[
                  { code: 1, title: 'Ativo' },
                  { code: 0, title: 'Inativo' },
                ]"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                required
                placeholder="Está confirmado?"
                v-model="filters.confirmed"
                :options="optionsConfirmed"
                :reduce="(option) => option.value"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                required
                placeholder="Unidade"
                v-model="filters.unit"
                :options="optionsUnits"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                required
                placeholder="Turno"
                v-model="filters.shift"
                :options="optionsShifts"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <flat-pickr
                v-model="filters.rangeDate"
                class="form-control"
                placeholder="Período de início"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>

          <div class="col-md-1">
            <button
              @click="getData()"
              style="padding: 14px !important"
              type="button"
              class="btn btn-primary btn-block"
            >
              <i v-if="!btnSearch" class="bi bi-search"></i>
              <b-spinner v-else small variant="light" />
            </button>
          </div>
        </div>
      </div>
    </b-card>

    <b-card no-body class="mb-0">
      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        :busy="btnSearch"
      >
        <template #cell(title)="data">
          {{ data.item.start_time | justTime }} às
          {{ data.item.end_time | justTime }} -
          {{ data.item.course_title }}
        </template>

        <template #cell(unit_name)="{ item }">
          {{ item.unit_name }}
        </template>

        <template #cell(postponement)="data">
          {{ data.item.postponement | datePT(false) }}
        </template>

        <template #cell(total)="data">
          {{ data.item.total | toCurrency() }}
        </template>

        <template #cell(confirmed_date)="data">
          {{
            data.item.confirmed_date
              ? `Sim, em ${datePT(data.item.confirmed_date)}`
              : "Não"
          }}
        </template>

        <template #cell(status)="data">
          <b-badge
            class="d-block"
            :variant="data.item.status === 1 ? 'light-success' : 'light-danger'"
          >
            {{ data.item.status === 1 ? "Ativo" : "Inativo" }}
          </b-badge>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              small
              class="align-middle"
              style="margin-right: 3px"
            ></b-spinner>
            <strong> carregando...</strong>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rowsTable="rowsTable"
        :currentPage="filters.currentPage"
        :perPage="filters.perPage"
        :totalRows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BTooltip,
  BFormRow,
  BForm,
  BSpinner,
} from "bootstrap-vue";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import moment from "moment";

export default {
  components: {
    BFormInput,
    CustomPaginateTable,
    BCard,
    BForm,
    BRow,
    flatPickr,
    BCol,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BFormRow,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      btnSearch: false,
      filters: {
        rangeDate: `${moment()
          .subtract("1", "year")
          .format("YYYY-MM-DD")} até ${moment().format("YYYY-MM-DD")}`,
        course: "",
        name: "",
        status: "",
        unit: "",
        shift: "",
        confirmed: "",
        currentPage: 1,
        perPage: 100,
      },
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      tableColumns: [
        {
          key: "unit_name",
          label: "Unidade",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
          thStyle: "width: 200px;display: table-cell;",
        },
        {
          key: "name",
          label: "Código",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "title",
          label: "Curso",
          sortable: false,
          class: "text-elipse-400",
        },
        {
          key: "confirmed_date",
          label: "Confirmado",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "postponements",
          label: "Adiamentos",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "shifty_title",
          label: "Período",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "postponement",
          label: "Início",
          sortable: false,
          class: "text-center",
          thStyle: "width: 110px",
        },
        {
          key: "orders",
          label: "Matrículas",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "total",
          label: "Total",
          sortable: false,
          class: "text-center",
          thStyle: "width: 110px",
        },
      ],
      optionsShifts: [],
      optionsUnits: [],
      optionsCourses: [],
      optionsConfirmed: [
        {
          title: "Sim",
          value: 1,
        },
        {
          title: "Nao",
          value: 0,
        },
      ],
    };
  },
  methods: {
    async fetchCourse(term) {
      this.optionsCourses = [];
      if (term.length > 2) {
        this.optionsCourses = await this.$store.dispatch(
          "Course/fetchSearch",
          term
        );
      }
    },

    updatePage() {
      this.getData();
    },
    datePT(date) {
      return new Date(date).toLocaleDateString("pt-BR");
    },
    async getData() {
      this.btnSearch = true;
      this.$store
        .dispatch("Classroom/getAllClassroomsStart", { ...this.filters })
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.filters.currentPage = data.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
        });
    },
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  async mounted() {
    this.getData();

    this.optionsShifts = await this.$store.dispatch("Shift/forSelect");
    this.optionsUnits = await this.$store.dispatch("Unit/forSelect");

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });
  },
  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.text-elipse-400 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
</style>
